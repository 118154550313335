import { config } from "../config";
import { HttpService } from "./HttpService";

export const ProductService = {
    getProduct
}

const BASE_URL = config.PRODUCT_URL;

async function getProduct(productBCD, userToken) {
    const product = await HttpService.get(`${BASE_URL}products/${productBCD}`, '', userToken)
    const RATE = 0.93
    console.log('product', product);
    if (product.price) product.price = (product.price * RATE).toFixed(2);
    if (product.similar_products) product.similar_products = product.similar_products.map(prod=> {return {...prod, price:(prod.price*RATE).toFixed(2)}})
    if (product.completing_products) product.completing_products = product.completing_products.map(prod=> {return {...prod, price:(prod.price*RATE).toFixed(2)}})
    console.log('product', product);
    return product
}