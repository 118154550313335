import React, { useEffect, useState, useRef, useMemo } from 'react';
import TagIcon from '../assets/img/tagIcon.svg';
import Barcode from '../assets/img/barcode.svg';
import { UserService } from '../services/UserService';
import Product from './Product';
import Avatar from './Avatar';
import SingleSelect from "react-custom-single-select";

// import useFitText from "use-fit-text";



const selectSizeHandler = (sizes, recommended_size) => {
    let sizesArray = sizes ? sizes.split('|') : null;
    if (sizesArray.includes(recommended_size)) return recommended_size;

    for (const size of sizesArray) {
        const splitted = size.split('/');
        if (splitted.length < 2) continue;
        else if (splitted[0] === recommended_size) {
            return size;
        }
    }
    return sizesArray[0];
}


export const Step3 = ({ user, product, setScannedItem }) => {
    const token = UserService.parseJwt(user.token);
    const provider = token?.firebase?.sign_in_provider;
    const name = [undefined, 'anonymous'].includes(provider) ? 'Guest' : user.full_name;
    const price = Number(product.price).toFixed(2);
    const stock = product.stock;
    const [showAvatar, setShowAvatar] = useState(false);
    const [sizePicked, setSizePicked] = useState(selectSizeHandler(product.size, product.recommended_size))
    const currency = useRef(JSON.parse(localStorage.getItem('MYSIZE-context') || {})?.isoCurrencyCode)

    const currencies = {
        USD: '$',
        GBP: '£',
        EUR: '€'
    }
    // const { fontSize, ref } = useFitText();

    useEffect(() => {
        document.documentElement.style.height = document.documentElement.scrollHeight + 'px';
    });

    const registerItem = () => {

        window.registerItem({
            "itemID": product.id,
            "unitOfMeasureCode": "PCE",
            "itemType": "CO",
            "actualUnitPrice": product.price,
            "quantity": 1,
            "receiptText": `${product.name}-${sizePicked}`.substring(0,38),
            "registrationNumber": product.id,
            "taxGroupID": "A1"
        });
    }

    const completingProducts = (product.completing_products.map((productItem, index) => <React.Fragment>
        <Product product={productItem} user={user} setScannedItem={setScannedItem} currency={currencies[currency.current]} key={productItem.id + '-' + index + Date.now()} />
        {product.completing_products.length !== index + 1 && <div className="product-seperator"></div>}
    </React.Fragment>
    ))
    const similarProducts = (product.similar_products.map((productItem, index) => <React.Fragment>
        <Product product={productItem} user={user} setScannedItem={setScannedItem} currency={currencies[currency.current]} key={productItem.id + '-' + index + Date.now()} />
        {product.similar_products.length !== index + 1 && <div className="product-seperator"></div>}
    </React.Fragment>
    ))
    let sizes = product.size ? product.size.split('|') : null;


    const similarItems = useMemo(() => <div className="products-container products-container--similar">
        <div className="products-container__title">Similar items</div>
        <div className="products-container__carousel">{similarProducts}</div>
    </div>, [])

    const completingItems = useMemo(() => <div className="products-container">
        <div className="products-container__title">Goes with</div>
        <div className="products-container__carousel completing">{completingProducts}</div>
    </div>, [])




    if (showAvatar) {
        return <Avatar avatarName={product.avatar} bodyType={user.bodyType} recommendedSize={product.recommended_size} selectedSize={sizePicked} setShowAvatar={setShowAvatar} />
        // return <Avatar gender={"male"} bodyType={user.bodyType} recommendedSize={"M"} setShowAvatar={setShowAvatar}/>
    }

    // console.log("stock:", stock);

    return (
        <section className="step3">
            <div className="top">
                {product.image_url && <img className="item-img" src={product.image_url === 'no_garment.svg' ? TagIcon : product.image_url} alt="Item" />}
                {/* {product.image_url && <img className="item-img item-img2" src={product.image_url==='no_garment.svg'?TagIcon:product.image_url} alt="Item" ref={ref}/>} */}
            </div>
            <div className="product-info">
                <div className="product-info__name">{product.name}</div>
                <div className={stock > 3 ? 'product-info__stock in-stock' : 'product-info__stock out-stock'}>{stock > 3 ? 'in stock' : stock === 0 ? 'Out Of Stock' : `Only ${stock} left in stock`}</div>
                <div className="product-info__price">{currencies[currency.current]}{price}</div>


                {/* {product.recommended_size && product.avatar && user.bodyType > 0 &&  <div className="product-info__3d-btn" onClick={() => setShowAvatar(prev => !prev)}>View this item in 3D!</div>} */}


                <div className="product-info__size product-info__button">

                    <div>{product.recommended_size ? `${"Your Size is: " + product.recommended_size}` : "No matching size"}</div>
                </div>
                <div className="product-info__size-picker product-info__button">
                    <SingleSelect
                        options={sizes}
                        customStyle={{
                            Item:{
                                'justify-content': 'center', 
                                'min-height':'3rem!important', 
                                backgroundColor:'white'
                            }, 
                            Header:{
                                'justify-content': 'center'
                            }, 
                            List:{
                                'overflow':'auto',
                                backgroundColor:'white',
                                border: 'none',
                                'z-index': '999'
                            }}}
                        onSelect={(option, {index}) => setSizePicked(option)}
                        placeholder={sizePicked}
                    />
                </div>
                {/* {console.log(user, product)} */}
                {user.bodyType > 0 &&
                    product.recommended_size &&
                    product.avatar &&
                    <div className="product-info__3d-btn product-info__button" onClick={() => setShowAvatar(prev => !prev)}>View this item in 3D!</div>
                }
                <div id="popup" className="product-info__add-to-basket product-info__button" onClick={registerItem}>
                    {/* <div className="product-info__user-name">Hi {name}</div> */}
                    <div>Add To Bag</div>
                </div>
                <div className="product-info__scan product-info__button">
                    <img src={Barcode} alt="barcode" />
                    <p>Or Scan Another Item</p>
                </div>
            </div>
            {similarProducts.length > 0 && similarItems}
            {completingProducts.length > 0 && completingItems}
        </section>
    )
}
