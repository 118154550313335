import { useEffect } from 'react';
import GirlImg from '../assets/img/girlImg.png';
import Logo from '../assets/img/logo.svg';
import Scanner from '../assets/img/scanner.svg';
import QRCode from '../assets/img/qrCode.svg';

export const Step1 = () => {
    useEffect(() => {
        setTimeout(()=>{
            document.documentElement.style.height = '';
        },10)
    // setTimeout(() => {window.SCAN('1234567890020')}, 2000)

    },[])

    return (
        <section className="step1">
            <div className="img">
                <img src={GirlImg} alt="Girl" />
            </div>
            <div className="main">
                <img src={Logo} alt="MYSIZE logo" />

                <div className="text">
                    <h1>Please Scan the</h1>
                    <h1>Customer’s profile</h1>
                </div>

                <div className="animation footer">
                    <img src={Scanner} alt="Scanner" />
                    <div className="qr-code">
                        <img src={QRCode} alt="QR Code" />
                        <span></span>
                    </div>
                    {/* <div>
                        <h2>Scan Your</h2>
                        <h2>Profile to Begin</h2>
                    </div> */}
                </div>
            </div>
        </section>
    )
}