import { config } from "../config";
import { HttpService } from "./HttpService";


const BASE_URL = config.USER_URL;

const  getUser = async(profileBCD) => {
    const retailerToken = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImUwOGI0NzM0YjYxNmE0MWFhZmE5MmNlZTVjYzg3Yjc2MmRmNjRmYTIiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL3NlY3VyZXRva2VuLmdvb2dsZS5jb20vbXlzaXplaWQtcmV0YWlsZXJzLXdlYnNpdGUiLCJhdWQiOiJteXNpemVpZC1yZXRhaWxlcnMtd2Vic2l0ZSIsImF1dGhfdGltZSI6MTYxMDI3NjQ3NCwidXNlcl9pZCI6IlZvZHRUMzUyZmloMGxDc1FwTEFvUEw1TkljSTIiLCJzdWIiOiJWb2R0VDM1MmZpaDBsQ3NRcExBb1BMNU5JY0kyIiwiaWF0IjoxNjEwMjc2NDc0LCJleHAiOjE2MTAyODAwNzQsImVtYWlsIjoiZ2tAbXlzaXplLmdrIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJmaXJlYmFzZSI6eyJpZGVudGl0aWVzIjp7ImVtYWlsIjpbImdrQG15c2l6ZS5nayJdfSwic2lnbl9pbl9wcm92aWRlciI6InBhc3N3b3JkIn19.fLIrm9ZTrHuM1KZZ6KvvR9do2XloNEFoYYm4mZ1jojtmb_tn3mk2CJxckfg0mw5NFC2TisZ7fwkeDP0-3kjY-b4hrjrYC1JgfPqvBDHGMohmSbXmtab4BnSc7niZ0ofmLwAOpE6Xd9X_W8XRgU3SL2Hr-BLr5cfypIjpqEJj4CkxzTEhHJ7qdQZcNvC88W4klCRLqh0jefdqmLtBMa3QmPZmAWV4QN8Af3vLo1AqvHSS1jAhFbL_JsrI6vfhubHrPTeJVgcBa723EI7rYVkTKHVwR35iUSKdVSCrcJF5UieqCKbL_dVEsUgt_5Dt_oT54aWsumoD1b_D2vFkyxPuzg';
    let user = await HttpService.get(`${BASE_URL}gk/users/me`, { barcode: profileBCD }, retailerToken)
    const body_type = await HttpService.get(`${BASE_URL}/users/me/body_type`, null, user.token);
    user.bodyType = Number(body_type.body_type); 
    return user
}

function parseJwt(token) {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

export const UserService = {
    getUser,
    parseJwt,
}
