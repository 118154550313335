import React, {useState, useEffect} from 'react';
import closeIcon from '../assets/img/close-icon.svg';
import SwitchButtonSmallCircle from './UI/SwitchButtonSmallCircle';
import { config } from '../config';

export default function Avatar({avatarName, bodyType, recommendedSize, selectedSize, setShowAvatar}) {

  return (
    <div className="avatar-container">
      <div className="close-overlay" onClick={() => setShowAvatar(false)}></div>
      <img src={closeIcon} className="avatar-container__close"  />
      <div className="panel">
        <div className="panel__size">Your Size is: {recommendedSize}</div>
        {/* <SwitchButtonSmallCircle
            state={inputSize}
            setState={setInputSize}
            // rendererComponent={PAGE_STATES.VISUALISATION_PAGE}
          >
            {sizesArray}
          </SwitchButtonSmallCircle> */}
        
          <div className="avatar">
          {/* https://360olga.s3.eu-west-1.amazonaws.com/male2/2/l/l/28.png */}
            {recommendedSize && <iframe src={`${config.REACT_APP_AVATAR_URL}?src=${avatarName}/${bodyType}/${recommendedSize.toLowerCase()}/${recommendedSize.toLowerCase()}`}></iframe>}
          </div>
      </div>
    </div>
  )
}
