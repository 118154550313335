import { PieTimer } from './PieTimer';

export const ErrorMsg = (props) => {
    return (
        <section className={`error-msg ${props.type}`}>
            <div className="screen"></div>
            <div className="content">
                <PieTimer duration={props.timer} />
                {props.children}
            </div>
        </section>
    )
}