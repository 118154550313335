import React, { useEffect, useState } from 'react';
import { scan, initScanner } from './services/ScannerService';
import { UserService } from './services/UserService';
import { ProductService } from './services/ProductService';
import { Nav } from './cmps/Nav';
import { LoaderCmp } from './cmps/LoaderCmp';
import { ErrorMsg } from './cmps/ErrorMsg';
import { Step1 } from './cmps/Step1';
import { Step2 } from './cmps/Step2';
import { Step3 } from './cmps/Step3';
// import logo from './logo.svg';
import './assets/style.scss';

const ERROR_TIME_OUT = 2000;
let timer;

const App = () => {
  const [step, setStep] = useState(1)
  const [scannedItem, setScannedItem] = useState(undefined)
  const [user, setUser] = useState(null)
  const [product, setProduct] = useState(null)
  const [error, setError] = useState(undefined)

  useEffect(() => {
    initScanner()
    document.body.addEventListener('keypress', handleScanner)
    window.SCAN = (userBarcode) => {
      if (typeof userBarcode !== 'string') {
        try {
          let e = new Error('Only STRINGS inputs are allowed. Please try again in this format: SCAN("123")');
          e.name = 'Wrong input!  => ';
          // e.stack = '';
          throw e;
        } catch (e) {
          console.error(e.name, e.message);
        }
      } else {
        userBarcode.split('').forEach(char => {
          handleScanner({ key: char })
        });
        handleScanner({ key: 'Enter' })
      }
    }
    return () => {
      document.body.removeEventListener('keypress', handleScanner)
      window.SCAN = undefined;
      clearTimeout(timer);
    }
  }, [])

  useEffect(() => {
    switch (step) {
      case 2:
        setProduct(null)
        break;

      default:
        break;
    }
  }, [step])

  useEffect(() => {
    if (!scannedItem) return
    setStep('loader');
    if (scannedItem.scanned === 'restart') {
      setStep(1);
      return
    }
    switch (step) {
      case 1:
        // get the user details from API
        UserService.getUser(scannedItem.scanned).then((user) => {
          if (user.code > 400) {
            setStep(1);
            setError({
              type: 'normal',
              timer: ERROR_TIME_OUT,
              body: <>
                <span>Sorry,</span>
                <br />
                Your profile didn't load
                {/* <br />
                <div className="btn-timer" style={{ "--duration": ERROR_TIME_OUT }} >
                  <button onClick={() => {
                    clearTimeout(timer);
                    setError(undefined)
                  }}>OK</button>
                </div> */}
              </>
            });
            timer = setTimeout(() => { setError(undefined) }, ERROR_TIME_OUT);
          } else {
            setUser(user)
            setStep(2)
          }
        })
        break;

      case 2:
        ProductService.getProduct(scannedItem.scanned, user.token).then((product) => {
          if (product.code > 400) {
            setStep(2);
            setError({
              type: 'normal',
              timer: ERROR_TIME_OUT,
              body: <>
                <span>Sorry,</span>
                <br />
                The item didn't load
                {/* <br />
                <button className="btn-timer" style={{ "--duration": ERROR_TIME_OUT }} onClick={() => {
                  clearTimeout(timer)
                  setError(undefined)
                }}>OK</button> */}
              </>
            });
            timer = setTimeout(() => { setError(undefined) }, ERROR_TIME_OUT);
          } else {
            setProduct(product)
            setStep(3)
          }
        })
        break;

      case 3:
        ProductService.getProduct(scannedItem.scanned, user.token).then((product) => {
          if (product.code > 400) {
            setError({
              type: 'normal',
              timer: ERROR_TIME_OUT,
              body:
                <>
                  <span>Sorry,</span>
                  <br />
                  The item didn't load
                  {/* <br />
                    <button onClick={() => setError(undefined)}>OK</button> */}
                </>
            });
            timer = setTimeout(() => { setError(undefined); setStep(3) }, ERROR_TIME_OUT);
          } else {
            setProduct(product)
            setStep(3)
          }
        })
        break;

      default:
        break;
    }
  }, [scannedItem])

  const handleScanner = ({ key }) => {
    const scanned = scan(key)
    if (scanned) {
      setScannedItem({ scanned })
      initScanner();
    }
  }

  return (
    <main>
      {step > 1 && <Nav setStep={setStep} step={step} />}
      {step === 'loader' && <LoaderCmp />}
      {step === 1 && <Step1 />}
      {step === 2 && <Step2 user={user} />}
      {step === 3 && <Step3 user={user} product={product} setStep={setStep} setScannedItem={setScannedItem} />}
      {error && <ErrorMsg type={error.type} timer={error.timer} nextStep={error.next}>{error.body}</ErrorMsg>}
      <div className="version">{`Version: ${require('../package.json').version}`}</div>
      {/* <div className="version">{`Version: 1.2.0 width:${window.innerWidth}px height:${window.innerHeight}px`}</div> */}
    </main>
  );
}

export default App;
