
var barcode = '';

export function initScanner() {
    barcode = '';
}

export function scan(key) {
    if (key === 'Enter') return barcode
    barcode += key
}