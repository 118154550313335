
// const api_base_url = process.env.REACT_APP_API_BASE_URL;
// const ROOT_URL = `${api_base_url}/api/v1/gk/users/me`;
// const ROOT_URL = `https://api.mysz.io/api/v1/`;
// 
// const ROOT_URL = "http://localhost:3001/api/v1";
// const ROOT_URL = "http://localhost:3000/api/v1";
// const ROOT_URL = "https://api-qa.mysz.io/api/v1";

const ROOT_URL = '';

export const HttpService = {
    get
}


// constructor(url_prefix = "") {
    //     this.url_prefix = url_prefix;
    //     this.getHeaders();
    // }
    
async function get(url, queryParams, userToken=null) {
    var headers = _getHeaders(userToken);
    try {
        let response = await fetch(
            ROOT_URL + url + '?' + _mapQueryParams(queryParams),
            {
                headers
            }
        );
        let jsonResponse = await response.json();
        return jsonResponse;
    } catch (error) {
        return null;
    }
}

// async post(url, body, queryParams = null) {
//     try {
//         let response = await fetch(
//             ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
//             {
//                 method: "POST",
//                 headers: this.headers,
//                 body: JSON.stringify(body),
//             }
//         );

//         let jsonResponse = await response.json();
//         return jsonResponse;
//     } catch (error) {
//         return null;
//     }
// }

// async put(url, body, queryParams = null) {
//     try {
//         let response = await fetch(
//             ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
//             {
//                 method: "PUT",
//                 headers: this.headers,
//                 body: JSON.stringify(body),
//             }
//         );
//         let jsonResponse = await response.json();
//         return jsonResponse;
//     } catch (error) {
//         return null;
//     }
// }

// async remove(url, queryParams = null) {
//     try {
//         let response = await fetch(
//             ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
//             {
//                 method: "DELETE",
//                 headers: this.headers,
//             }
//         );
//         let jsonResponse = await response.json();
//         return jsonResponse;
//     } catch (error) {
//         return null;
//     }
// }

// getUrl(url) {
//     return this.url_prefix + url;
// }

function _getHeaders(userToken) {
    return ({
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Auth-Token": userToken
    });
}

function _mapQueryParams(queryParams) {
    return queryParams
        ? Object.keys(queryParams)
            .map(function (key) {
                return key + "=" + queryParams[key];
            })
            .join("&")
        : "";
}
