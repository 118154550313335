import React, { useState } from 'react'
import { ProductService } from '../services/ProductService';
import  {Step3}  from './Step3';

export default function Product({product, user, setScannedItem, currency}) {

    const getProduct = async() => {
        setScannedItem({scanned: product.barcode})
    }

    const fixedName = (name) => {
      let lowerCase = name.toLowerCase();
      return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1)
    }


    const prod = (<div className="product" key={product.id}>
        <div className="product__image"><img src={product.image_url} alt="prod"/></div>
        <div className="product__name" >{fixedName(product.name)}</div>
        <div className="product__price">{`${currency}${product.price}`}</div>
    </div>
    )
// string.charAt(0).toUpperCase() + string.slice(1)

 

  return (
    <div onClick={getProduct}>{prod}</div>
  )
}
