export const PieTimer = ({duration}) => {
    return (
        <div className="pie-timer">
            <div className="wrapper" style={{"--duration": duration}}>
                <div className="pie spinner"></div>
                <div className="pie filler"></div>
                <div className="mask"></div>
            </div>
        </div>
    )
}