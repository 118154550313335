import { useEffect } from 'react';
import Barcode from '../assets/img/barcode.svg';
import UserProfile from '../assets/img/userProfile.svg';
import { UserService } from '../services/UserService';


export const Step2 = ({ user }) => {
    console.log('step2 user:',user);
    const token = UserService.parseJwt(user.token);
    const name = !token.firebase || token.firebase.sign_in_provider === "anonymous" ? 'Guest' : user.full_name;

    useEffect(() => {
        setTimeout(()=>{
            document.documentElement.style.height = document.documentElement.scrollHeight + 'px';
        },10)
    // setTimeout(() => {window.SCAN('6744804851827')}, 2000)

    },[])

    return (
        <section className="step2">
            <div className="user">
                <h1>Hi {name},</h1>
                <h3>Welcome to the <br/> MySizeID Mirror Experience.</h3>
            </div>
            {/*<h3>Welcome to the</h3>
            <h3>MySizeID Mirror Experience.</h3> */}
            <div className="scan">
                <img src={Barcode} alt="Barcode Icon" />
                <span></span>
            </div>
            <h3>Please scan an item…</h3>
        </section>
    )
}