import SmallLogo from '../assets/img/smallLogo.png';
import Redo from '../assets/img/redo.svg';
import Restart from '../assets/img/qr-restart.png';
import cart from '../assets/img/cart.svg';
import cartFull from '../assets/img/cart-green.svg';
import { useEffect, useState } from 'react';

export const Nav = ({ step, setStep }) => {
    // const switchUser = () => {
    //     setStep(1);
    // }
    
    
    
    // const [isCartFull, setIsCartFull] = useState(false);

    // useEffect(() => {
    //     window.addEventListener('message', handleMessage);
    //     return () => {
    //         window.removeEventListener('message', handleMessage);
    //     }
    // },[])

    // const handleMessage = ({data}) => {
    //     setIsCartFull(data==='MYSIZE-cart-full' ? true : false)
    // }

    return (
        // <nav>
        //     {/* <img src={SmallLogo} alt="Small Logo" /> */}
        //     <button onClick={switchUser}>
        //         <img src={Restart} className="qr-restart" alt="Restart" />
        //         <div>
        //             {/* <img src={Redo} className="redo" alt="Restart" />Restart */}
        //         </div>
        //     </button>
        // </nav>


        <nav>
            <img src={SmallLogo} alt="logo"/>
            {step > 2 && <div className="cart" onClick={() => {
                setStep(1); 
                window.postMessage('MYSIZE-cart-empty', '*');
                window.oAppEnablementCommonInstance.hideBrowser()
            }}>
                {/* { cartSize > 0 && <div className="cart__counter">{cartSize}</div>} */}
                <img src={cart} className="cart-icon" alt="cart icon" />
            </div>}
        </nav>
    )
}